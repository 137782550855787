.link-group {
	display: block;

	&--primary {
		color: $color-branding-1;

		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

// Overwrite heading (h1, h2, h3) styles
.link-group__title {
	margin: 0;

	color: inherit;

	font-size: $font-size-s;

	.link-group--primary & {
		color: inherit;

		font-weight: bold;
	}
}

.link-group__label {
	display: inline-block; // Removes underlined link: http://tinyurl.com/jkwawcx

	color: $color-gray;
}
