.card {
	@extend %box;

	display: flex;
	flex-direction: column;

	overflow: hidden;

	margin-bottom: $space-s;

	// Linked module
	&[href] {
		color: $color-text;
		text-decoration: none;

		&:hover,
		&:focus,
		&:active {
			background-color: $color-white;
			border-color: $color-branding-1;
		}
	}
}

.card__header {
	padding: 0 0 $space-s;

	// Reset margins of direct children (mostly of headings)
	> * { // stylelint-disable-line selector-max-universal
		margin: 0;
	}

	// Header without spacing
	&--collapsed {
		margin: (-$space-s) (-$space-s) 0;
	}
}

.card__body {
	flex-grow: 1;

	> :last-child,
	> .btn {
		margin-bottom: 0;
	}

	&.is-collapsed {
		max-height: 15rem !important; // stylelint-disable-line declaration-no-important
	}
}

.card__footer {
	padding: $space-s 0 0;

	> .btn {
		margin-bottom: 0;
	}
}

.card--hero {
	@extend %card-background-image;

	// Because the aspect ratio padding technique doesn't work with Flexbox, set the background image on the first child of the card
	// Credit: http://bit.ly/2GWUt74
	> div:first-child {
		@extend %card-background-image;

		padding-top: 0;

		&:before {
			margin: (-$space-s) (-$space-s) $space-s;
			padding-bottom: calc(56.5% + 1rem); // 16:9;
		}
	}

	> .card__header,
	> .card__body,
	> .card__footer {
		background: $color-white;
	}

	> .card__header {
		padding-bottom: 0;
	}

	> .card__header,
	> .card__body {
		padding-top: $space-s;
	}
}

.card--banner {
	@extend %card-background-image;

	&:before {
		min-height: 14rem;
		margin: (-$space-s) (-$space-s) $space-s;
	}

	@include respond-to(medium) {
		flex-direction: column;
		align-items: flex-end;

		min-height: 12rem;

		&:before {
			position: absolute;
			top: 0;
			right: 50%; // Same as card width in banner
			bottom: 0;
			left: 0;

			margin: 0 ($gutter / -4) 0 0;
		}

		> .card__header,
		> .card__body,
		> .card__footer {
			width: 50%;

			padding-right: $gutter * 1.75;
			padding-left: $gutter * 1.75;

			background: $color-white;
		}

		> .card__header,
		> .card__footer {
			flex: 0 0 auto;
		}

		> .card__body {
			padding-top: 0;
		}
	}
}

.card--performance {
	.card__header {
		flex: 1 0 auto;

		overflow: hidden;
	}

	.card__body {
		flex: 0 0 auto;

		text-align: right;
	}

	.card__footer {
		.btn,
		.btn-group {
			margin-bottom: 0;
		}
	}
}

.card--clickable {
	@extend %card-clickable;
}

// Larger underlyings cards for highest tops/flops
// TODO: find better solution? Flex?
.card--performance-large {
	height: 16rem;
}

.card--scrollable {
	overflow-y: scroll;

	height: 16rem;

	border-top-right-radius: 0;
	border-bottom-right-radius: 0;

	.card__header {
		flex-shrink: 0; // Prevent header from shrinking
	}
}

.card--scrollable-large {
	height: 55vh;

	@include respond-to(small) {
		height: 60vh;
	}
}

.card--selected {
	border: $border-width solid $color-branding-1;
}

.card--unstyled {
	@extend %card-unstyled;
}

.card--unstyled-banner {
	@extend %card-unstyled;
	@extend %card-background-image;

	> .card__header {
		padding-top: $space-s;

		@include respond-to(medium) {
			padding-top: 0;
		}
	}

	&:before {
		height: 8rem;
		margin: 0;

		border-radius: $border-radius;

		@include respond-to(small) {
			height: 14rem;
		}

		@include respond-to(medium) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 50%;

			height: auto;
		}
	}

	@include respond-to(medium) {
		min-height: 12rem;

		> .card__header,
		> .card__body,
		> .card__footer {
			width: 50%;
			padding-right: $space-m;
		}

		> .card__header,
		> .card__footer {
			flex: 0 0 auto;
		}
	}
}

%card-background-image {
	background-image: inherit;
	background-size: 0; // Hide background-image
	background-repeat: no-repeat; // Set background to no-repeat. Otherwise background-color doesn't work

	&:before {
		display: block;

		background: center / cover; // Show background-image
		background-image: inherit; // Inherit background-image
		background-repeat: inherit;

		content: '';
	}
}

%card-clickable {
	> a {
		display: block;
		flex-grow: 1;

		text-decoration: none;
	}

	&:hover {
		border-color: $color-branding-1;
	}
}

%card-unstyled {
	padding: 0;

	background-color: transparent;
	border: none;
	border-radius: 0;
}
