.loader {
	position: relative;

	width: 16px;
	height: 16px;

	background: url('/assets/img/animations/loader-clock.svg');
}

.loader__pointer {
	position: absolute;
	top: 15%;
	left: 8px;

	transform-origin: center bottom;

	width: 1px;
	height: 6px;

	background: $color-branding-1;
	border-radius: $border-radius;

	animation: rotate 1s linear infinite;
}
