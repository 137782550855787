.tooltip {
	position: absolute;
	z-index: 2;

	display: none;

	max-width: 15rem;

	padding: $space-xxs $space-xs;

	color: $color-white;
	font-size: $font-size-s;
	text-align: left;

	background: $color-supporting-1;
	border-radius: $border-radius;

	&:after {
		position: absolute;

		transform: rotate(45deg);

		width: $space-xs;
		height: $space-xs;


		background: inherit;

		content: '';
	}

	// Positioning including .top, .right, .bottom, .left classes for tooltip plugin

	&,
	&.right {
		margin-left: $space-xxs;

		&:after {
			top: 50%;
			left: -$space-xxs;

			margin-top: -$space-xxs;
		}
	}

	&--top,
	&.top {
		margin-top: -$space-xxs;

		&:after {
			top: auto;
			bottom: -$space-xxs;
			left: $space-s;
		}
	}

	&--bottom,
	&.bottom {
		margin-top: $space-xxs;

		&:after {
			top: -$space-xxs;
			left: $space-s;

			margin-top: 0;
		}
	}

	&--left,
	&.left {
		margin-left: -$space-xxs;

		&:after {
			top: 50%;
			right: -$space-xxs;
			left: auto;

			margin-top: -$space-xxs;
		}
	}

	&--help {
		z-index: 20;

		display: block;

		max-width: 12rem;
		margin-left: $space-xs * -1;

		padding: $space-xxs $space-xs;

		&:after {
			top: 50%;
			right: -$space-xxs;
			left: auto;

			margin-top: -$space-xxs;
		}

		&.right {
			margin-left: $space-xs;
		}

		@include respond-to(small) {
			max-width: 20rem;
		}
	}


	// States for validation

	&.is-visible {
		display: block;
	}
}
