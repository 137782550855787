.table-wrapper {
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;

	// No bottom margin is needed when table is only child in a box or card
	&:not(:only-child) {
		margin-bottom: $space-s;
	}
}
