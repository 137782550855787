$modal-width: 32rem;

.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 20;

	display: flex;
	align-items: center;
	justify-content: center;

	background: $color-overlay;
}

.modal__body {
	@include respond-to(small) {
		max-width: $modal-width;
	}
}
