.media {
	@include clearfix;

	&:not(:only-child) {
		margin-bottom: $space-s;
	}

	// Media object directly followed by another media object
	& + & {
		margin-top: $space-s;
	}
}

.media__body {
	overflow: hidden;
}

.media__visual {
	float: left;

	max-width: 33.33%;
	margin: 0 $space-xs 0 0;

	// Alternating media object with visual on the right
	.media--alt & {
		float: right;

		margin-right: 0;
		margin-left: $space-xs;
	}
}
