// Brand colors
$color-branding-1:					#ff6200;

// Supporting colors
$color-supporting-1:				#525199;
$color-supporting-2:				#60a6da;
$color-supporting-3:				#ab0066;
$color-supporting-4:				#d0d93c;
$color-supporting-5:				#349651;

// Supporting 60%
$color-supporting-1-light:			#9898c3;
$color-supporting-2-light:			#9fcaea;
$color-supporting-3-light:			#cc70a4;
$color-supporting-4-light:			#e3e88a;
$color-supporting-5-light:			#85c097;

// // Supporting 30%
$color-supporting-1-lighter:		#cbcce1;
$color-supporting-2-lighter:		#cee5f5;
$color-supporting-3-lighter:		#e5b8d2;
$color-supporting-4-lighter:		#f1f4c4;
$color-supporting-5-lighter:		#c2dfca;

// Supporting 15%
$color-supporting-1-lightest:		#e6e5f0;
$color-supporting-2-lightest:		#e8f3fa;
$color-supporting-3-lightest:		#f3dce9;
$color-supporting-4-lightest:		#f8f9e2;
$color-supporting-5-lightest:		#e1efe5;

// Neutral colors
$color-black:						#000;
$color-gray:						#767676;
$color-gray-light:					#a8a8a8;
$color-gray-lighter:				#dcdcdc;
$color-white:						#fff;

// Functional colors
$color-positive:					#349651;
$color-positive-light:				#eaf4ef;
$color-positive-tick:				rgba($color-positive, 0.3);
$color-negative:					#f00;
$color-negative-light:				#fef3f4;
$color-negative-tick:				rgba($color-negative, 0.3);
$color-negative-border:				#ff5959;

// Background and text
$color-background:					#f0f0f0;
$color-line:						#d9d9d9;
$color-overlay: 					rgba($color-white, 0.8);
$color-text:						#333;
$color-text-select:					rgba($color-text, 0.75);

// Social media colors
$color-facebook:					#3b5998;
$color-google-plus:					#dc4e41;
$color-instagram:					#3f729b;
$color-linkedin:					#0e76a8;
$color-twitter:						#00acee;
$color-youtube:						#cd201f;

// Dev
$color-debug:						#ff69b4;
