// SMACCS State: always start your state class with '.is-'

/* stylelint-disable declaration-no-important */

.is-hidden {
	display: none !important;
}

.is-visible {
	display: block !important;
}

.is-fixed {
	position: fixed !important;
}

.is-collapsed {
	overflow: hidden;

	max-height: 0;
}

.is-expanded {
	max-height: 9999rem;
}

.is-positive {
	color: $color-positive;
}

.is-negative {
	color: $color-negative;
}
