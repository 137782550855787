%nav-link {
	display: block;

	color: inherit;
	text-decoration: none;

	&:hover {
		color: $color-branding-1;
		text-decoration: underline;
	}
}

.nav {
	position: absolute;
	top: 0;
	left: -999rem; // Hide off-screen.
	z-index: 12;

	background: $color-white;

	&.is-visible {
		position: relative;
		left: 0;
	}

	@include respond-to(medium) {
		position: relative;
		left: 0;
	}
}

// Main nav toggle button
.nav__toggle {
	float: right;

	& + & {
		margin-right: $space-xxs;
	}

	@include respond-to(medium) {
		display: none;
	}
}

// List containing navigation items
.nav__list {
	@include clearfix;

	// Create space for menu button on mobile
	margin-top: $space-m;

	text-align: left;

	@include respond-to(medium) {
		float: left;

		margin-top: $space-xs;
	}
}

// Navigation list item
.nav__item {
	position: relative;

	// Apply border styles to all nav items, except for the last item
	&:not(:last-child) {
		border-bottom: $border-width solid $color-line;

		@include respond-to(medium) {
			border-right: $border-width solid $color-line;
			border-bottom-color: transparent;
		}
	}

	@include respond-to(medium) {
		float: left;

		margin-left: -$border-width;

		border: $border-width solid transparent;

		&:hover {
			> .nav__list--sub {
				top: 100%;
				left: auto;

				z-index: -1;
			}
		}
	}
}

// Links within a nav item
.nav__link {
	@extend %nav-link;

	margin-left: -$border-width;
	padding: $space-xs 0;

	@include respond-to(medium) {
		display: inline-block;

		padding: 0 $space-xs;
	}

	&.is-active {
		color: $color-branding-1;
	}
}

.nav__btn {
	margin-top: $space-s;

	@include respond-to(medium) {
		margin: -$space-s 0;
		margin-left: -$border-width;
	}
}

// Sub navigation list within nav item
.nav__list--sub {
	position: absolute;
	left: -999rem;

	margin-top: 0;

	border-top: $border-width solid $color-line;

	@include respond-to(medium) {
		min-width: 14rem;
		margin: $border-width * -1;

		background: $color-white;
		border: $border-width solid $color-line;
		border-radius: 0 $border-radius $border-radius;
	}

	&.is-visible {
		position: relative;
		left: 0;

		@include respond-to(medium) {
			position: absolute;
			left: -999rem;
		}
	}

	// Nav items within a sub navigation list
	& .nav__item {
		float: none;

		padding-left: $space-xs;

		@include respond-to(medium) {
			margin: 0 $space-xs;
			padding: 0 $space-xs 0 0;

			// Styling for "non mobile" sub nav items
			&:not(:last-child) {
				border-right: none;
				border-bottom: $border-width solid $color-line;
			}

			& .nav__link {
				display: block;

				padding: $space-xs 0;
			}
		}
	}
}

.nav__item--has-sub {
	// Create a tab like style on hover
	&:hover {
		@include respond-to(medium) {
			margin: (-$space-xs) 0 (-$space-xxs) -1px;
			padding: $space-xs 0 $space-xxs;

			background: $color-white;
			border: $border-width solid $color-line;
			border-bottom: none;
			border-radius: $border-radius $border-radius 0 0;
		}
	}
}

// Toggle buttons for mobile sub navigation
.nav__sub-toggle {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 5;

	padding: 0 $space-xs;

	background: $color-white; // cover the horizontal lines of sub list items

	border: none;
	border-left: $border-width solid $color-line;

	outline: none;

	cursor: pointer;

	.icon {
		pointer-events: none;
	}

	.is-active & {
		transform: rotate(180deg);

		border-right: $border-width solid $color-line;
		border-left: none;

		@include respond-to(medium) {
			transform: rotate(0deg);

			padding-left: 0;

			border: none;
		}
	}

	@include respond-to(medium) {
		position: relative;

		margin-left: -$space-xs;
		padding-left: 0;

		background: transparent;
		border: none;
	}
}

// Switch between states (Language or profile)
.nav__selection {
	padding-top: $space-s;

	text-align: right;

	border-top: $border-width solid $color-line;

	> .nav__list {
		@include respond-to(0, medium) {
			margin-top: 0;
		}
	}

	@include respond-to(medium) {
		float: right;

		padding-top: $space-xs;

		border-top: none;
	}
}
