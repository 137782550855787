.tabs {
	@extend %list-unstyled;
	@include clearfix;

	z-index: 5; // Overlap card

	margin-bottom: -4px; // Overlap card

	@include respond-to(small) {
		margin-bottom: 0;
		margin-left: $space-xs;
	}
}

.tabs__item {
	margin-bottom: -$border-width;
	padding: $space-xs 0;

	color: $color-gray;

	background: $color-white;
	border: $border-width solid $color-line;

	@include respond-to(small) {
		float: left;

		background: none;
		border: none;
	}

	&:first-child {
		border-radius: $border-radius $border-radius 0 0;

		.tabs__label {
			border-left-color: transparent;
		}
	}

	&.is-active {
		position: relative;
		z-index: 2; // Overlap card

		padding-left: $space-xs;

		color: $color-branding-1;

		background: $color-white;

		border: $border-width solid $color-line;

		.tabs__label {
			padding-left: 0;

			border-left: none;
		}

		@include respond-to(small) {
			border-bottom: none;
			border-radius: $border-radius $border-radius 0 0;
		}
	}
}

.tabs__label { // stylelint-disable-line no-descending-specificity
	display: block;

	margin-left: -$border-width;
	padding: 0 $space-xs;

	color: inherit;

	text-decoration: none;

	@include respond-to(small) {
		border-left: $border-width solid $color-line;
	}

	&:hover { // stylelint-disable-line no-descending-specificity
		text-decoration: underline;
	}
}
