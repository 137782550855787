p {
	margin: 0 0 $space-s;

	font-size: $font-size-s;
}

a {
	color: $color-text;
	text-decoration: none;

	outline: none;

	p & {
		@extend %link-alt;
	}

	&:active,
	&:focus,
	&:hover {
		color: $color-branding-1;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin: 0 0 $space-s;

	color: $color-branding-1;
	font-family: $typeface-display;
	font-weight: $font-weight-normal;

	a { // stylelint-disable-line no-descending-specificity
		color: $color-branding-1;
		text-decoration: none;

		&:hover {
			color: $color-text;
		}
	}

	+ p {
		margin-top: -$space-xs;
	}
}

h1,
.h1 {
	font-size: $font-size-l;

	@include respond-to(small) {
		font-size: $font-size-xl;
	}

	& + .topic {
		margin-top: -$space-s;
		margin-bottom: $space-s;

		color: $color-branding-1;
	}
}

h2,
.h2 {
	font-size: $font-size-l;
}

h3,
.h3 {
	font-size: $font-size-m;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-size: $font-size-s;
	font-weight: $font-weight-bold;
}

h5,
.h5,
h6,
.h6 {
	color: $color-text;
}

// Alternate link
// Primary styling for links within text
%link-alt {
	color: $color-branding-1;
	text-decoration: underline;

	outline: none;

	&:active,
	&:focus,
	&:hover {
		color: $color-text;
	}
}

blockquote {
	margin-right: 0;
	margin-left: 0;

	font-size: $font-size-s;
	font-weight: $font-weight-bold;
}

cite {
	font-style: italic;
}

code {
	font-family: $typeface-mono;
	line-height: 1;

	background: $color-gray-light;
}

small {
	display: inline-block;

	font-size: $font-size-xs;

	& + & {
		margin-top: $space-xs;
	}
}

sub,
sup {
	line-height: 1;
}

hr {
	height: 1px;

	color: $color-line;
	font-size: 0;

	background: $color-line;
	border: none;
}

::selection {
	color: $color-background;
	text-shadow: none;

	background: $color-text-select;
}
