.header {
	flex-shrink: 0;

	padding: $space-s 0;

	background: $color-white;
}

// Header flex container
.header__container {
	@extend %container;

	position: static;

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.header__logo {
	flex: 0 1 auto;

	@include respond-to(medium) {
		flex: 0 1 auto;
	}
}

.header__search {
	flex: 1 0 100%;

	order: 1;

	margin: $space-s 0;

	@include respond-to(medium) {
		flex-basis: auto;
		order: 0;

		margin-right: $space-m;
		margin-left: $space-m;
	}
}

.header__nav {
	flex: 1 1 auto;
}

.header__buttons {
	flex: 0 1 auto;
}
