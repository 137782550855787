.img {
	&.img-fixed-height {
		max-width: none;
		max-height: none;
	}

	&--app-store {
		width: 135px;
		height: 40px;
	}

	&--s {
		width: $img-s;

		&.img-fixed-height {
			height: $img-s;
		}
	}

	&--m {
		width: $img-m;

		&.img-fixed-height {
			height: $img-m;
		}
	}

	&--l {
		width: $img-l;

		&.img-fixed-height {
			height: $img-l;
		}
	}

	&--xl {
		width: $img-xl;

		&.img-fixed-height {
			height: $img-xl;
		}
	}

	&--xxl {
		width: $img-xxl;

		&.img-fixed-height {
			height: $img-xxl;
		}
	}

	&--rounded {
		border-radius: $border-radius;
	}

	&--circle {
		border-radius: $border-radius-full;
	}

	&--border {
		padding: $space-xs;

		border: $border-width solid $color-branding-1;
		border-radius: $border-radius-full;
	}
}
