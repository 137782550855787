.performance {
	position: relative;
}

.performance__value {
	margin: 0;
	padding: 0;

	color: $color-text;

	white-space: nowrap;

	will-change: contents;
}

// Down arrow by default
.performance__indicator {
	display: inline-block;

	// Up arrow
	&.is-positive,
	.is-positive & {
		transform: rotate(180deg);
	}
}

.performance__percentage {
	white-space: nowrap;

	will-change: contents;
}
