.meta-list {
	@extend %box;
	@extend %list-unstyled;

	display: flex;
	flex-direction: column;

	overflow: hidden;

	margin-bottom: $space-s;
	padding: 0;

	@include respond-to(small) {
		flex-flow: row wrap;
	}
}

.meta-list__item {
	position: relative;
	flex: 0 0 auto;

	margin-bottom: -($border-width);
	padding: ($gutter / 2) $gutter;

	text-align: center;

	border-bottom: $border-width solid $color-line;

	@include respond-to(small) {
		width: 50%;
		padding: $gutter;
	}

	@include respond-to(medium) {
		width: (100% / 3);
	}

	@include respond-to(large) {
		width: (100% / 6);
	}

	// Faux border
	&:after {
		position: absolute;
		top: 0;
		right: -1px;
		bottom: 0;

		width: 0;

		border-right: $border-width solid $color-line;
		content: '';
	}

	&--small {
		padding: $gutter ($gutter / 2);

		text-align: left;

		border: none;

		&:after {
			border-right: none;
		}
	}
}
