.meta {
	display: inline-block;

	// Meta directly followed by another definition
	& + & {
		margin-left: $space-s;
	}
}

.meta__heading {
	margin-bottom: $space-xxs;

	color: $color-gray-light;

	font-size: $font-size-s;
}

.meta__value {
	font-size: $font-size-s;

	// Meta value directly followed by another definition value
	& + & {
		margin-left: $space-xs;
	}

	@include respond-to(medium) {
		font-size: $font-size-m;
	}

	&--s {
		display: inline-block;

		margin-bottom: $space-xs;

		font-weight: $font-weight-bold;

		@include respond-to(medium) {
			font-size: $font-size-s;
		}
	}

	&--l {
		font-size: $font-size-l;

		@include respond-to(medium) {
			font-size: $font-size-xl;
		}
	}
}
