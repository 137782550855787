table {
	width: 100%;

	border-collapse: collapse;
	border-spacing: 0;

	tr {
		border-top: $border-width solid $color-line;
	}

	th {
		font-weight: $font-weight-bold;
	}

	th,
	td {
		padding: 0.5rem $space-s;

		text-align: left;
		vertical-align: middle;

		// Can be useful on anchors to make whole cell clickable
		.fill-cell {
			display: block;
		}

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	&.table-compact { // stylelint-disable-line selector-no-qualifying-type
		th,
		td {
			padding: $space-xxs $space-s $space-xxs 0;

			&:last-child {
				padding-right: 0;
			}
		}
	}

	thead {
		color: $color-branding-1;

		tr {
			border-top: none;
		}

		th { // stylelint-disable-line no-descending-specificity
			padding-top: 0;
		}
	}

	tfoot {
		font-weight: $font-weight-bold;
	}
}

