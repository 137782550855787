$sidenav-color:	#232323;

.sidenav-is-open {
	@include respond-to(0, small) {
		position: fixed;
	}
}

.sidenav {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 12;


	display: flex;
	flex-direction: column;
	justify-content: center;

	transform: translateX(-100%);

	background: $sidenav-color;

	transition: opacity 0.2s, transform 0.2s;

	@include respond-to(medium) {
		position: relative;

		transform: none;

		background: none;

		.is-invisible & {
			.js & {
				opacity: 0;
			}
		}
	}

	.sidenav-is-open & {
		transform: translateX(0);
	}
}

.sidenav__list {
	&:last-of-type {
		border-top: $border-width solid $sidenav-color;
	}

	&.is-sidenav-fixed {
		@include respond-to(medium) {
			position: fixed;
			top: 0;
		}
	}

	&--secondary {
		margin-top: $border-width * -1; // Prevent double border

		&.is-sidenav-fixed {
			@include respond-to(medium) {
				top: auto;
				bottom: 0;
			}
		}
	}
}

.sidenav__item {
	@include respond-to(medium) {
		border-bottom: $border-width solid $sidenav-color;
	}
}

.sidenav__link {
	position: relative;

	display: block;

	padding: $space-xs 0 $space-xs $space-m;

	color: $color-white;

	&:hover {
		color: $color-gray-lighter;

		background: $color-black;

		transition: background-color 0.3s;
	}

	&:active,
	&:focus {
		color: $color-branding-1;
	}

	&.is-active {
		color: $color-branding-1;
	}

	@include respond-to(medium) {
		padding: $space-s;

		color: $color-gray-light;

		&:hover {
			background: $sidenav-color;

			.sidenav__title {
				z-index: 2;
				display: block;

				&--popover { // stylelint-disable-line max-nesting-depth
					z-index: 3;
				}
			}
		}
	}
}

.sidenav__icon {
	width: 24px;
	height: 24px;
}

.sidenav__title {
	display: inline-block;

	margin-left: $space-xs;

	font-size: $font-size-m;

	vertical-align: middle;

	@include respond-to(medium) {
		position: absolute;
		bottom: $space-xxs;
		left: 82%;

		display: none;

		padding: $space-xs;

		color: $color-white;

		font-size: $font-size-s;
		white-space: nowrap;

		background: $color-branding-1;
		border-radius: $border-radius;

		&:before {
			position: absolute;
			bottom: $space-s;
			left: -$space-xxs;

			transform: rotate(45deg);

			width: $space-xs;
			height: $space-xs;

			background: inherit;

			content: '';
		}
	}

	&--popover {
		display: block;

		margin: $space-xs $space-xs 0 0;

		font-size: $font-size-s;
		white-space: normal;

		@include respond-to(medium) {
			display: block;

			width: 22rem;
			margin-left: $space-xs;
			padding: $space-s $space-m 0 $space-s;
		}
	}
}

.sidenav__popover-close {
	position: absolute;
	top: $space-xs;
	right: $space-xs;

	display: none;

	@include respond-to(medium) {
		display: block;
	}
}

.sidenav__close {
	position: fixed;
	top: $space-s;
	right: $space-s;

	@include respond-to(medium) {
		display: none;
	}
}
