.placeholder {
	position: relative;

	margin-bottom: $space-s;

	color: $color-gray-light;
	font-size: $font-size-m;
	text-align: center;

	background: transparent;

	border: $border-width dashed $color-gray-light;
	border-radius: $border-radius;

	outline: none;
	cursor: pointer;

	&.is-active,
	&:hover {
		color: $color-branding-1;

		border-color: $color-branding-1;
	}
}

.placeholder__body {
	padding: $space-s;
}

.placeholder__drop-target {
	display: inline-block;

	padding: $space-s;

	pointer-events: none;
}

.placeholder__controls {
	margin-top: $space-s;

	text-align: center;

	> * { /* stylelint-disable-line selector-max-universal */
		display: inline-block;
	}
}
