// Font faces

@font-face {
	font-family: ing-me;
	font-style: normal;
	src: url('/assets/fonts/INGMeWeb-Regular.woff2') format('woff2'),
		url('/assets/fonts/INGMeWeb-Regular.woff') format('woff'),
		url('/assets/fonts/INGMeWeb-Regular.ttf') format('truetype');
}

@font-face {
	font-family: ing-me-narrow;
	font-style: normal;
	src: url('/assets/fonts/INGMeNarrowWeb-Regular.woff2') format('woff2'),
		url('/assets/fonts/INGMeNarrowWeb-Regular.woff') format('woff'),
		url('/assets/fonts/INGMeNarrowWeb-Regular.ttf') format('truetype');
}

/* stylelint-disable value-keyword-case */
$typeface-display:				'ing-me', 'Trebuchet MS', Helvetica, sans-serif;
$typeface-body:					'ing-me', 'Trebuchet MS', Helvetica, sans-serif;
$typeface-narrow:				'ing-me-narrow', 'Trebuchet MS', Helvetica, sans-serif;
$typeface-mono:					Consolas, Lucida Console, monospace;
/* stylelint-enable value-keyword-case */

$font-size-xs:					0.813rem; 		// 13px
$font-size-s:					0.938rem; 		// 15px
$font-size-m:					1.25rem; 		// 20px
$font-size-l:					1.563rem; 		// 25px
$font-size-xl:					1.875rem; 		// 30px

$font-weight-normal:			400;
$font-weight-bold:				700;
