.embed__body {
	display: inline-block; // In case video needs to be centered

	// Target iframe, embed, video, object etc.
	> * { /* stylelint-disable-line selector-max-universal */
		display: block; // http://stackoverflow.com/questions/21025319/iframe-creates-extra-space-below
	}

	.embed--responsive & {
		position: relative;

		display: block;

		// 16:9 = 56.25%
		// 4:3	= 75%
		padding-bottom: 56.25%;

		> * { /* stylelint-disable-line selector-max-universal */
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: calc(100% - 2px); // Distract 2px from the height to prevent a black top and bottom border in the embed
		}
	}
}

