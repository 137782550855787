.load-mask {
	border-radius: inherit;

	&:after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;

		background: $color-overlay url('/assets/img/animations/ing-loader.gif') no-repeat center;
		border-radius: inherit;

		content: '';
	}

	&.fullscreen {
		position: fixed;
	}
}

// Add is loading class to element with load mask
// Ensure load mask is always visible
.is-loading {
	min-height: 5rem;
}
