@mixin render-supporting-button($color) {
	$color-hover: rgba($color, 0.6);

	background-color: $color;
	border-color: $color;

	&.is-active,
	&:active,
	&:focus,
	&:hover {
		color: $color-white;

		background-color: $color-hover;
		border-color: transparent;
	}
}

@mixin render-ghost-button($color) {
	color: $color;

	background-color: $color-white;
	border-color: $color;

	&.is-active,
	&:focus,
	&:hover,
	&:active {
		color: $color-white;

		background-color: $color;
		border-color: $color;
	}
}

// Default and Supporting buttons
// Identifies the primary action in a set of buttons

.btn {
	display: inline-block;

	padding: $space-xxs $space-xs;

	color: $color-white;
	font-family: inherit;

	line-height: 1.5;

	text-align: center;
	text-decoration: none;

	background: $color-branding-1;
	border: $border-width solid $color-branding-1;
	border-radius: $border-radius;

	cursor: pointer;

	appearance: none;

	&.is-active,
	&:active,
	&:focus,
	&:hover {
		color: $color-branding-1;

		background-color: $color-white;
		border-color: $color-branding-1;
	}

	// Icon-only buttons
	> .icon:only-child {
		margin-right: 0;
	}

	&[disabled] {
		&,
		&:active,
		&:focus,
		&:hover {
			color: $color-gray;

			background-color: $color-line;
			border-color: transparent;

			cursor: not-allowed;
		}
	}

	&--supporting-1 {
		@include render-supporting-button($color-supporting-1);
	}

	&--supporting-2 {
		@include render-supporting-button($color-supporting-2);
	}

	&--supporting-3 {
		@include render-supporting-button($color-supporting-3);
	}

	&--loading {
		cursor: not-allowed;

		&,
		&.is-active,
		&:focus,
		&:hover,
		&:active {
			color: $color-branding-1;

			background-color: $color-white;
			border-color: $color-branding-1;
		}

		.icon { display: none; } // stylelint-disable-line no-descending-specificity

		.loader {
			top: 2px;

			display: inline-block;

			margin-right: $space-xxs;
		}
	}

	// Buttons displaying only an icon
	&--icon {
		padding: 0;

		color: inherit;

		background: none;

		border: none;

		&:focus,
		&:hover,
		&:active {
			color: inherit;

			background: transparent;
		}

		&:first-child {
			margin-left: 0;
		}
	}

	// Buttons styled as they are a normal link
	&--link {
		color: $color-text;

		&,
		&:focus,
		&:hover {
			background: none;
			border: none;
			border-radius: 0;
		}
	}

	// Large button from small size on
	&--large {

		@include respond-to(small) {
			margin: $space-xs 0;
			padding: $space-xxs $space-s;

			font-size: $font-size-m;
		}
	}

	&--desktop {
		display: none;

		@include respond-to(medium) {
			display: inline-block;
		}
	}

	// Ghost buttons
	// Identifies the secondary action in a set of buttons
	&--ghost {
		@include render-ghost-button($color-branding-1);
	}

	&--ghost-supporting-1 {
		@include render-ghost-button($color-supporting-1);
	}

	&--ghost-supporting-2 {
		@include render-ghost-button($color-supporting-2);
	}

	&--ghost-supporting-3 {
		@include render-ghost-button($color-supporting-3);
	}

	// Fluid buttons
	// Creates block level buttons on mobile, that span the full width of its parent.
	&--fluid-mobile {
		display: block;

		width: 100%;

		@include respond-to(small) {
			display: inline-block;

			width: auto;
		}
	}

	// Social media buttons.
	// Buttons identified by the social platforms branding color
	&--facebook {
		@include render-supporting-button($color-facebook);
	}

	&--twitter {
		@include render-supporting-button($color-twitter);
	}

	&--instagram {
		@include render-supporting-button($color-instagram);
	}

	&--linkedin {
		@include render-supporting-button($color-linkedin);
	}

	&--google-plus {
		@include render-supporting-button($color-google-plus);
	}

	&--youtube {
		@include render-supporting-button($color-youtube);
	}
}
