%custom-inputs {
	> input {
		position: absolute;
		left: -999rem;

		+ label {
			padding: $space-xxs $space-xs; // Overwrite default label padding
		}

		&:checked {
			// stylelint-disable-next-line selector-max-compound-selectors
			+ input[type='hidden'] + .btn--ghost,
			+ .btn--ghost {
				color: $color-white;

				background: $color-branding-1;
			}

			// stylelint-disable-next-line selector-max-compound-selectors
			+ input[type='hidden'] + .btn--ghost-supporting-1,
			+ .btn--ghost-supporting-1 {
				color: $color-white;

				background: $color-supporting-1;
			}
		}
	}
}

%btn-disabled {
	position: relative;

	&:after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		background: $color-overlay;

		content: '';
		cursor: not-allowed;
	}
}

.btn-container {
	@extend %custom-inputs;

	display: inline-block;

	margin: 0 0 $space-xs;

	> .btn {
		display: inline-block;

		margin: 0 0 $space-xxs;
	}

	&.is-disabled {
		@extend %btn-disabled;
	}

	&--sticky {
		position: fixed;
		right: $space-s;
		bottom: $space-s;

		z-index: 10;

		margin: 0;

		> .btn {
			margin-bottom: 0;
		}
	}
}

.btn-group {
	@extend %custom-inputs;
	@include clearfix;

	display: inline-block;

	width: 100%;
	margin: 0 0 $space-xs;

	vertical-align: bottom;

	&.is-disabled {
		@extend %btn-disabled;
	}

	@include respond-to(small) {
		width: auto;
	}

	// Btn-group directly followed by a submit button
	+ .btn {
		margin-left: $space-xxs;

		vertical-align: top;
	}

	> .btn {
		float: left;

		width: 100%;
		margin: 0 0 (-$border-width);

		border-radius: 0;

		&:first-child,
		&:first-of-type {
			margin-left: 0;

			border-radius: $border-radius $border-radius 0 0;
		}

		&:last-child,
		&:last-of-type {
			border-radius: 0 0 $border-radius $border-radius;
		}

		@include respond-to(small) {
			display: inline-block;

			width: auto;
			margin: 0 0 0 (-$border-width);

			&:first-child,
			&:first-of-type {
				margin-left: 0;

				border-radius: $border-radius 0 0 $border-radius;
			}

			&:last-child,
			&:last-of-type {
				border-radius: 0 $border-radius $border-radius 0;
			}
		}
	}

	&--fluid {
		display: flex;

		> .btn { /* stylelint-disable-line no-descending-specificity */
			margin: 0 (-$border-width) 0 0;

			text-align: center;

			&:first-child,
			&:first-of-type {
				border-radius: $border-radius 0 0 $border-radius;
			}

			&:last-child,
			&:last-of-type {
				border-radius: 0 $border-radius $border-radius 0;
			}
		}

		@include respond-to(small) {
			> .btn {
				flex: 1 0 1px;
			}
		}
	}
}
