@keyframes fillWidth {
	from {
		width: 0%;
	}

	to {
		width: 100%;
	}
}

@keyframes marquee {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(-100%);
	}
}

@keyframes pulseFade {
	from {
		box-shadow: 0 0 0 0 $color-white, 0 0 0 1px $color-branding-1;
	}

	to {
		box-shadow: 0 0 0 3px transparent, 0 0 0 4px transparent;
	}
}

@keyframes pulseScale {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.25);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes bounce {
	0% {
		transform: translate3d(0, 0, 0);
	}

	40% {
		transform: translate3d(0, -2px, 0);
	}

	53% {
		transform: translate3d(0, 0, 0);
	}

	70% {
		transform: translate3d(0, -1px, 0);
	}
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}


@keyframes flashOpacity {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0.75;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
