.marquee {
	position: relative;

	display: flex;

	overflow: hidden;

	&:hover {
		.marquee__list {
			animation-play-state: paused;
		}
	}
}

.marquee__list { // stylelint-disable-line no-descending-specificity
	@extend %list-unstyled;

	display: flex;
	flex: 0 0 auto;

	// animation: marquee 12s linear infinite; // Set via JS
}

.marquee__item {
	white-space: nowrap;
}

.marquee__anchor {
	display: block;

	padding: $space-xs;

	color: $color-text;

	font-weight: $font-weight-bold;

	text-decoration: none;

	@include respond-to(medium) {
		padding-right: $space-s;
		padding-left: $space-s;
	}
}
