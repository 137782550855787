.autocomplete {
	position: relative;

	&.is-open {
		.btn {
			border-bottom-right-radius: 0;
		}
	}
}

.autocomplete__input {
	margin-right: 0 !important; // stylelint-disable-line declaration-no-important

	.is-open & {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.autocomplete__results {
	@extend %list-unstyled;

	position: absolute;
	top: 100%;
	right: 0;
	left: 0;

	z-index: 9000;

	overflow: auto;

	max-height: 350px;

	background: $color-white;
	border: $border-width solid $color-line;
	border-top: none;
	border-radius: 0 0 $border-radius $border-radius;

	&:empty {
		border: none;
	}

	&[aria-hidden='true'] {
		display: none;
	}
}

.autocomplete__item {
	&:not(:last-child) {
		border-bottom: $border-width solid $color-line;
	}

	&:hover,
	&.is-active {
		background: $color-supporting-1-lightest;
	}

	&--spacing {
		padding: $space-xs;
	}
}

.autocomplete__link {
	display: block;

	padding: $space-xs;

	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}
