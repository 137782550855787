.custom-input {
	&,
	&[type='checkbox'],
	&[type='radio'] {
		position: absolute;
		left: -9999px; // Hide off screen

		+ label {
			margin: 0 -$space-xxs;
			padding: 0 $space-xxs; // Expand click area

			color: transparent; // Fill

			vertical-align: inherit;

			stroke: $color-branding-1;
		}
	}

	&:checked,
	&:hover {
		+ label {
			color: $color-branding-1;
		}
	}

	&:checked,
	&.is-checked {
		+ label {
			animation: pulseScale 0.3s ease-in-out;
		}
	}

	&:disabled {
		+ label {
			color: transparent; // Fill
			stroke: $color-gray;
		}
	}
}
