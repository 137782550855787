$space-xxs:						0.313rem;
$space-xs:						0.625rem;
$space-s:						1rem;
$space-m:						2.5rem;
$space-l:						5rem;
$space-xl:						7.5rem;

$border-width:					1px;
$border-radius:					6px;
$border-radius-full:			50%;

$icon-s:						16px;
$icon-m:						$icon-s * 2;
$icon-l:						$icon-s * 3;
$icon-xl:						$icon-s * 4;

$img-xxs:						0.25rem;
$img-xs:						0.5rem;
$img-s:							1rem;
$img-m:							2rem;
$img-l:							4rem;
$img-xl:						10rem;
$img-xxl:						20rem;

$input-max-width: 30rem;
