.slider {
	position: relative;
}

.slider__container {
	overflow-x: auto;

	.is-active & {
		overflow-x: hidden;
	}
}

.slider__inner {
	transition: transform 300ms;
}

.slider__controls {
	display: none;

	@include respond-to(medium) {
		display: block;
	}
}

.slider__control {
	position: absolute;
	top: 50%;

	transform: translateY(-50%);

	padding: $space-xs;

	background: $color-white;
	border: $border-width solid $color-line;
	border-radius: $border-radius-full;

	&:active,
	&:focus,
	&:hover {
		border-color: $color-branding-1;
	}

	&--left {
		left: -$space-s;
	}

	&--right {
		right: -$space-s;
	}
}
