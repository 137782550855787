.chart {
	// Chart as background
	// Used in cards on markets page
	&--bg {
		.chart-container {
			position: absolute;
			bottom: 0;
			left: 0;

			width: 100%;
			height: 75%;
		}
	}
}
