.meta-block {
	@extend %box;

	overflow: hidden; // Prevent background animations from overlapping

	margin-bottom: $space-s;
	padding: 0;

	text-align: center;
}

.meta-block__row {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;

	width: 100%;
}

.meta-block__item {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-items: stretch;

	margin-top: $border-width * -1; // Hide double border-bottom
	margin-left: $border-width * -1; // Hide double border-left
	padding: $space-s $space-s $space-xs;

	border-top: 1px solid $color-line;
	border-left: 1px solid $color-line;

	&:first-child,
	&:last-child {
		flex-grow: 2;
	}
}

.meta-block__sub-row {
	display: flex;
	flex: 1 auto;
	flex-direction: row;
	align-items: stretch;
}

.meta-block__sub-item {
	flex: 1 auto;
}

.meta-block__label {
	padding: 0;

	color: $color-gray-light;
	font-size: $font-size-s;
}

.meta-block__sublabel {
	display: block;

	padding: 0;

	color: $color-gray-light;
	font-size: $font-size-xs;
}

.meta-block__value {
	padding-bottom: 0;

	color: $color-text;
	font-size: $font-size-l;
	font-weight: $font-weight-normal; // Overwrite font-weight for heading styles

	white-space: nowrap;
}

.meta-block__timestamp {
	margin-bottom: 0;

	color: $color-gray-light;
	font-size: $font-size-xs;
}
