form {
	display: inline-block;
}

fieldset {
	margin: 0;
	padding: 0;

	border: none;
}

label {
	display: block;

	padding-bottom: $space-xs;

	cursor: pointer;

	+ fieldset {
		display: inline-block;
	}
}


input {
	&[type='radio'],
	&[type='checkbox'] {
		&,
		~ label {
			display: inline-block;

			width: auto;

			margin: 0;
			padding: 0;
		}

		~ label {
			max-width: $input-max-width;
			margin-right: $space-xs;
			vertical-align: middle;
		}
	}

	// Overwrite native styling
	&[type='email'],
	&[type='number'],
	&[type='password'],
	&[type='search'],
	&[type='text'],
	&[type='url'] {
		appearance: none;
	}

	&[type='number'] {
		appearance: textfield;

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			appearance: inherit;
		}
	}

	&[type='file'] {
		margin-bottom: $space-xs;
	}
}

textarea {
	min-height: 8rem;

	appearance: none;
}

input,
select,
textarea {
	display: block;

	width: 100%;
	max-width: $input-max-width;

	padding: $space-xs;

	color: $color-text;

	font-family: $typeface-body;
	font-weight: $font-weight-normal;

	background: $color-white;
	border: $border-width solid $color-line;
	border-radius: $border-radius;

	outline: none;

	&:focus {
		background: $color-white;
		border: $border-width solid $color-branding-1;
	}

	&:disabled {
		color: $color-gray-light;

		border-color: $color-background;

		cursor: not-allowed;

		+ label {
			color: $color-gray;

			cursor: not-allowed;
		}
	}

	&:invalid {
		box-shadow: none;
	}
}
