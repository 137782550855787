.tick {
	&--border {
		&:after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			border: $border-width solid transparent;
			border-radius: $border-radius;

			transition: opacity 0.4s;

			content: '';
			opacity: 0;
			pointer-events: none;
		}

		&.tick-positive {
			&:after {
				border-color: $color-positive;

				opacity: 1;
			}
		}

		&.tick-negative {
			&:after {
				border-color: $color-negative;

				opacity: 1;
			}
		}
	}
}

.tick__text {
	transition: opacity 0.4s;

	.tick-positive &,
	.tick-negative & {
		opacity: 0.25;
	}
}
