.notification {
	padding: $space-xs;

	text-align: center;

	background: $color-supporting-1-lightest;
	border-bottom: ($border-width * 3) solid $color-supporting-1;

	&.is-fixed {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 999;
	}


	&--supporting-2 {
		background: $color-supporting-2-lightest;
		border-bottom: $border-width * 3 solid $color-supporting-2;
	}

	&--supporting-3 {
		background: $color-supporting-3-lightest;
		border-bottom: $border-width * 3 solid $color-supporting-3;
	}

	&--cta {
		margin-bottom: $space-s;

		color: $color-white;
		text-align: center;

		background: $color-branding-1;

		border: $border-width solid $color-branding-1;
		border-radius: $border-radius;

		cursor: pointer;

		&:hover {
			color: $color-branding-1;

			background: $color-white;
		}
	}
}

.notification__body {
	@extend %container;
}

.notification__link {
	&,
	&:active,
	&:hover,
	&:visited {
		color: $color-black;
	}

	&:hover {
		text-decoration: none;
	}
}
