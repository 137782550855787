html {
	box-sizing: border-box;
	overflow-y: scroll;
	height: 100%;

	background-color: $color-white;
}

/* stylelint-disable selector-max-universal */
* {
	&,
	&:before,
	&:after {
		box-sizing: inherit; // Credit: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
	}
}
/* stylelint-enable selector-max-universal */

body {
	min-height: 100%;
	margin: 0;
	padding: 0;

	color: $color-text;
	font-family: $typeface-body;
	font-size: $font-size-s;
	font-feature-settings: 'liga';

	line-height: 1.5;
	text-rendering: optimizeLegibility;

	background: $color-background;

	// Expose media query breakpoints to the outside using the 'content' property of the ':before' pseudo element! :)
	&:before {
		@include breakpoint-sync;
	}
}
