.topic {
	display: block;

	padding-bottom: $space-xxs;

	font-size: $font-size-s;
	font-weight: $font-weight-normal;

	line-height: 1.5;
}
