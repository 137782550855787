%toggle-btn {
	padding: $space-xs ($space-s + $space-xxs);

	text-align: center;

	&,
	&:active,
	&:focus {
		color: $color-white;

		background: $color-black;
	}

	&:hover {
		color: $color-white;

		background: $color-branding-1;
	}
}

// Body class
.is-dashboard {
	display: flex;
	flex-direction: column;

	max-width: 100%;
	height: 100%; // IE11 fix
}

.dashboard {
	position: relative;

	display: flex;
	flex: 1 0 auto;
}

.dashboard__sidebar {
	flex: 0 0 auto;

	@include respond-to(medium) {
		background: $color-text;
	}
}

.dashboard__main {
	flex-grow: 1;

	overflow: hidden; // IE11 fix
}

.dashboard__toggle-nav {
	@extend %toggle-btn;

	@include respond-to(medium) {
		display: none;
	}
}

.dashboard__toggle-fullscreen {
	@extend %toggle-btn;

	display: none;

	@include respond-to(medium) {
		display: block;
	}
}

.dashboard__header {
	display: flex;
}

.dashboard__tape {
	background: $color-gray-lighter;
}

.dashboard__body {
	position: absolute;
	right: 0;
	left: 0;

	overflow: hidden;

	padding: $space-s;

	background: $color-background;

	@include respond-to(medium) {
		position: relative;
	}
}

.dashboard__column {
	&--small {
		.hide-column-small {
			display: none;
		}
	}
}
