img {
	width: auto;
	max-width: 100%;
	height: auto;

	vertical-align: middle;
}

iframe {
	margin: 0;
	padding: 0;

	border: none;
}

figure {
	margin: $space-xs; // Overwrite normalize.css
}
