
$gutter:						1rem;

$ezpz-namespace:				'.grid';
$ezpz-grid-type:				'fractions';

$ezpz-grid-align:				true;
$ezpz-grid-wrap:				true;
$ezpz-cell-order:				true;
$ezpz-cell-offset:				true;
$ezpz-cell-align:				true;
$ezpz-gutter-collapse:			true;

$ezpz-breakpoints: 				$breakpoints;
$ezpz-gutter:					$gutter;

$ezpz-fractions:				(1:2), (1:3), (2:3), (1:4), (3:4), (1:6);
