.embed-card {
	@extend %box;

	display: flex;
	flex-direction: column;

	padding: 0;

	@include respond-to(medium) {
		flex-direction: row;
	}
}

.embed-card__media {
	@include respond-to(medium) {
		// Don't use flex shorthand! Doesn't work with calc() in IE11 :'(
		// https://github.com/philipwalton/flexbugs#8-flex-basis-doesnt-support-calc
		flex-basis: calc(50% - #{$space-xs});
		flex-shrink: 0;
	}
}

.embed-card__body {
	padding: $space-s;

	@include respond-to(medium) {
		// IE 11 fix
		// http://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
		flex: 1 1 100%;
	}
}
