.panel {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 15;

	transform: translateX(100%);

	width: 100%;
	height: 100%;
	padding: $space-xs;

	background: $color-white;

	transition: transform 0.25s;

	.panel-is-open & {
		transform: translateX(0);

		overflow-y: auto;

		box-shadow: 0 0 8px $color-line;
	}

	@include respond-to(small) {
		max-width: 42rem;
		padding: $space-m;
	}
}

// Close button with higher z-index than load-mask
.panel__close {
	position: relative;
	z-index: 20;

	float: right;
}

// Body class
.panel-is-open {
	@include respond-to(0, small) {
		position: fixed;
	}
}
