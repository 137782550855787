/* stylelint-disable selector-no-qualifying-type */

%list-unstyled {
	margin: 0;
	padding: 0;

	list-style: none;
}

ol,
ul {
	margin: 0 0 $space-xs;

	&.list-img {
		@include clearfix;

		li {
			float: left;

			padding: $space-xs;
		}
	}

	&.list-inline {
		@include clearfix;

		display: inline-block; // Makes it possible to center the list

		padding: 0;

		vertical-align: middle;

		li {
			float: left;

			padding: 0 $space-xs;

			border-left: $border-width solid $color-line;

			&:first-child {
				padding-left: 0;

				border-left: none;
			}
		}
	}

	// Reset lists within forms and navigation
	nav &,
	form &,
	&.list-unstyled,
	&.list-img {
		@extend %list-unstyled;
	}
}
