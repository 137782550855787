.icon {
	width: $icon-s;
	height: $icon-s;

	margin: -3px 0 0;
	vertical-align: middle;

	fill: currentColor;

	// IE11 fix. Prevent child elements of icon/SVG from having pointer events
	> * { // stylelint-disable-line selector-max-universal
		pointer-events: none;
	}

	&--m {
		width: $icon-m;
		height: $icon-m;
		margin: 2px 0 0;

		&-aligned {
			position: relative;
			top: -0.25rem;
		}
	}

	&--l {
		width: $icon-l;
		height: $icon-l;
	}

	&--xl {
		width: $icon-xl;
		height: $icon-xl;
	}
}
