%container {
	position: relative;

	max-width: 80em;

	margin-right: auto;
	margin-left: auto;
	padding: 0 $gutter;
}

.container {
	@extend %container;

	&--fluid {
		max-width: none;
	}
}

.main {
	min-height: 25rem;
	padding: $space-s 0;
}
