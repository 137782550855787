%box {
	position: relative;

	margin-bottom: $space-s;
	padding: $space-s;

	background: $color-white;
	border: $border-width solid $color-line;
	border-radius: $border-radius;

	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.box {
	@extend %box;

	&[href] {
		display: block;

		&:hover {
			border-color: $color-branding-1;
		}
	}
}
