.formula {
	display: flex;
	align-items: center;

	margin: $space-xs 0 $space-s;

	font-family: $typeface-narrow;
}

.formula__fraction {
	display: inline-block;

	text-align: center;

	> .formula__value {
		display: block;

		min-height: 4.5rem;

		&:first-child {
			border-bottom: $border-width solid $color-branding-1;
		}

		@include respond-to(small) {
			min-height: 0;
		}
	}
}

.formula__operator {
	padding: 0 $space-xxs;

	color: $color-branding-1;
	font-weight: $font-weight-bold;
}
