.bar {
	margin-bottom: $space-xxs;

	@include respond-to(medium) {
		display: flex;

		flex-flow: row-reverse wrap;
		align-items: center;
	}

	&:last-of-type {
		margin-bottom: $space-s;
	}

	&:only-child {
		margin-bottom: 0;
	}
}

.bar__meter {
	position: relative;

	overflow: hidden;

	width: 100%;
	height: $space-xs;

	background: $color-background;
	border-radius: $border-radius;

	@include respond-to(medium) {
		flex: 1 1 50%;

		margin-right: $space-xs;
	}
}

.bar__fill-container {
	display: block;

	height: inherit;

	transition: width 200ms, background-color 200ms;
}

.bar__fill {
	height: inherit;

	color: $color-supporting-2;

	background-color: $color-supporting-2;

	animation: fillWidth 0.7s ease-in-out;

	.is-positive & {
		background-color: $color-positive;
	}

	.is-negative & {
		background-color: $color-negative;
	}
}

.bar__header {
	flex: 1 1 100%;

	margin-bottom: $space-xxs;

	color: $color-text;
	text-decoration: none;
}

.bar__label {
	font-size: $font-size-xs;

	white-space: nowrap;

	@include respond-to(medium) {
		flex: 0 1 5rem;

		text-align: right;
	}
}
