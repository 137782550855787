.image-clip {
	position: relative;

	overflow: hidden;

	padding-bottom: 55%; // ~16:9
}

.image-clip__visual {
	position: absolute;
	top: -17.5%;
	left: 0;

	width: 100%;
}
