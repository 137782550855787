.teaser__body {
	position: relative;

	overflow: hidden;

	max-height: 24rem;

	&:after {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;

		height: $space-xl;

		background: linear-gradient(to bottom, transparent 0%, $color-white 90%);

		content: '';
	}
}

.teaser__footer {
	margin: $space-s 0 $space-m;
	padding-top: $space-m;

	text-align: center;

	border-top: $border-width solid $color-line;

	ul,
	ol {
		margin-top: 0;
		padding-top: $space-s;

		list-style-position: inside;
	}
}
