.footer {
	padding: $space-s 0;

	background: $color-white;
}

.footer__list {
	max-width: 100%;
	padding-left: 0;
}

.footer__list__item {
	display: inline-block;

	width: 49%;

	&:before {
		min-width: 0;
		content: '';
	}

	@include respond-to(medium) {
		width: 24.5%;
	}

	@include respond-to(large) {
		width: auto;
		margin-left: $space-xs;
		padding-left: $space-xs;

		border-left: $border-width solid $color-line;

		&:first-child {
			margin-left: 0;
			padding-left: 0;

			border-left: none;
		}
	}
}

.footer__link {
	color: $color-gray-light;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.footer__social {
	@include respond-to(small) {
		text-align: right;
	}
}

.footer__social,
.footer__credits {
	margin-top: $space-s;
}
