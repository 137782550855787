@mixin ellipsis($width: 100%) {
	overflow: hidden;

	max-width: $width;

	text-overflow: ellipsis;

	white-space: nowrap;
	word-wrap: normal;
}
