/* stylelint-disable declaration-no-important */

// Text alignment, transform and colors

.text {
	// Alignment

	&-right {
		text-align: right !important;
	}

	&-left {
		text-align: left !important;
	}

	&-center {
		text-align: center !important;
	}

	&-justify {
		text-align: justify !important;
	}

	&-nowrap {
		white-space: nowrap !important;
	}

	&-truncate {
		display: block !important;

		overflow: hidden !important;

		text-overflow: ellipsis !important;
		white-space: nowrap !important;
	}

	&-shadow {
		text-shadow: 2px 2px 1px $color-white !important;
	}

	// Transform

	&-uppercase {
		text-transform: uppercase !important;
	}

	&-lowercase {
		text-transform: lowercase !important;
	}

	&-capitalize {
		text-transform: capitalize !important;
	}

	// Colors

	&-body {
		color: $color-text !important; // body color
	}

	&-muted {
		color: $color-gray-light !important;
	}

	&-primary {
		color: $color-branding-1 !important;
	}

	&-white {
		color: $color-white !important;
	}

	&-supporting-1 {
		color: $color-supporting-1 !important;
	}

	&-supporting-2 {
		color: $color-supporting-2 !important;
	}

	&-supporting-3 {
		color: $color-supporting-3 !important;
	}

	&-supporting-4 {
		color: $color-supporting-4 !important;
	}

	&-positive {
		color: $color-positive !important;
	}

	&-negative {
		color: $color-negative !important;
	}
}

// Font weight and italics

.font {
	&-bold {
		font-weight: $font-weight-bold !important;
	}

	&-normal {
		font-weight: $font-weight-normal !important;
	}

	&-italic {
		font-style: italic !important;
	}

	&-narrow {
		font-family: $typeface-narrow !important; // Use narrow for saving space in tables
	}
}

// Background colors

.bg {
	&-default {
		background-color: $color-background !important;
	}

	&-muted {
		background-color: $color-line !important;
	}

	&-primary {
		background-color: $color-branding-1 !important;
	}

	&-white {
		background-color: $color-white !important;
	}

	&-supporting-1 {
		background-color: $color-supporting-1-lightest !important;
	}

	&-supporting-2 {
		background-color: $color-supporting-2-lightest !important;
	}

	&-supporting-3 {
		background-color: $color-supporting-3-lightest !important;
	}
}

// Spacing and borders

.no {
	&-margin {
		margin: 0 !important;
	}

	&-padding {
		padding: 0 !important;
	}

	&-border {
		border: none !important;
	}

	&-underline {
		text-decoration: none !important;
	}
}

// Floats

.float {
	&-right {
		float: right !important;
	}

	&-left {
		float: left !important;
	}
}


// Screen readers

.sr-only {
	@include sr-only;
}

.sr-only-focusable {
	@include sr-only;
	@include sr-only-focusable;
}

// Responsive utility classes

@each $breakpoint, $value in $breakpoints {
	// Table cells (eg .toggle-small)
	.toggle-#{$breakpoint} {
		display: none;

		@include respond-to($breakpoint) {
			display: table-cell;
		}
	}

	// Show on certain breakpoint (eg .show-large)
	.show-#{$breakpoint} {
		display: none !important;

		@include respond-to($breakpoint) {
			display: block !important;
		}
	}

	// Text alignment (eg .text-medium-right)
	@include respond-to($breakpoint) {
		.text-#{$breakpoint}-right {
			text-align: right !important;
		}

		.text-#{$breakpoint}-left {
			text-align: left !important;
		}

		.text-#{$breakpoint}-center {
			text-align: center !important;
		}

		.text-#{$breakpoint}-justify {
			text-align: justify !important;
		}
	}
}

// No javascript toggle

.js {
	.js-hidden {
		display: none !important;
	}
}
