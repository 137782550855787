.widget {
	@extend %box;

	padding: 0;

	border: none;

	box-shadow: 1px 1px 3px $color-line;

	&.is-dragging {
		border: $border-width dashed $color-branding-1;

		opacity: 0.75;
	}

	&.is-dropped {
		animation: bounce 0.5s;
	}

	&.is-loading {
		min-height: 10rem;
	}

	&.is-blocked {
		> .widget__header,
		> .widget__body,
		> .widget__footer {
			pointer-events: none;
		}
	}
}

.widget__header { // stylelint-disable-line no-descending-specificity
	padding: $space-xxs $space-xs;

	color: $color-white;

	background: $color-branding-1;
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;

	cursor: move;
}

.widget__body { // stylelint-disable-line no-descending-specificity
	padding: $space-xs;

	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;

	> :last-child {
		margin-bottom: 0;
	}
}

.widget__footer { // stylelint-disable-line no-descending-specificity
	padding: $space-xxs $space-xs;

	font-size: $font-size-xs;
	text-align: right;

	background: $color-white;
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
}
