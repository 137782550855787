.form {
	display: block;
}

.form__item {
	display: block;

	margin-bottom: $space-s;

	// Nested module within a form (for example: password strength meter)
	&--module {
		max-width: $input-max-width;
	}
}

.form__label {
	font-weight: $font-weight-bold;
}

.form__field {
	&.input-validation-error, // .net model validation generated classname.
	&.is-invalid {
		background-color: $color-supporting-3-lightest;
		border-color: $color-supporting-3;

		&:focus {
			border-color: $color-black;
		}
	}

	&-validation {
		position: relative;

		width: 100%;
		max-width: $input-max-width;

		&-msg,
		&-icon {
			display: none;
		}

		&-msg {
			padding-top: $space-xs;
			padding-left: $space-xs;

			color: $color-supporting-3;
			font-weight: $font-weight-bold;

			&:before {
				position: absolute;
				top: 0;

				width: 0;
				height: 0;

				border-top: 4px solid $color-supporting-3;
				border-right: 4px solid transparent;
				border-left: 4px solid transparent;

				content: '';
			}
		}

		&-icon {
			position: absolute;
			top: -2.5rem;
			right: $space-xs;
		}

		&.is-invalid {
			.form__field-validation-msg {
				display: block;
			}
		}

		&.is-valid {
			.form__field-validation-icon {
				display: block;
			}
		}
	}
}

.form__required-indicator {
	color: $color-branding-1;
}

.form--inline {
	@include respond-to(small) {
		.form__field,
		.form__item {
			display: inline-block;

			margin-right: $space-xxs;

			line-height: 1.5;
		}

		.form__item {
			vertical-align: top; // IE11 fix

			&--actions {
				margin-top: $space-s * 2; // Align with form field
			}
		}

		.form__label {
			display: block;
		}

		.form__field {
			width: auto;
		}

		.btn {
			padding: calc(#{$space-xs} - #{$border-width});

			vertical-align: inherit;
		}
	}
}
