$timeline-bullet-size: 8px;

.timeline {
	padding: 0 0 0 $space-s;

	list-style: none;
}

.timeline__item {
	position: relative;

	&:before {
		position: absolute;
		top: 0.5rem;
		left: -$space-xs;

		width: $timeline-bullet-size;
		min-width: 0; // Overwrite default list item styling
		height: $timeline-bullet-size;

		background: $color-text;
		border-radius: $border-radius-full;

		content: '';
	}

	&:not(:last-child) {
		padding-bottom: $space-xs;

		&:after {
			position: absolute;
			top: $timeline-bullet-size * 2.5;
			bottom: -($timeline-bullet-size / 2);
			left: calc(#{($timeline-bullet-size - $border-width) * -1});

			width: $border-width;

			background: $color-text;

			content: '';
		}
	}

	&.has-ended {
		color: $color-gray-light;

		&:before,
		&:after {
			background: $color-line;
		}
	}

	&.is-active {
		color: $color-branding-1;

		&:before {
			background: $color-branding-1;

			animation: pulseFade 1s infinite;
		}

		&:after {
			background: $color-branding-1;
		}
	}
}

.timeline__label {
	float: left;

	padding: 0 $space-s;
}

.timeline__text {
	overflow: hidden;

	margin: 0;
}
