.input-group {
	display: flex;

	> .btn {
		flex: 0 0 auto;

		margin-bottom: 0;

		border: $border-width solid $color-line;
		border-radius: $border-radius;

		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	> input {
		flex: 1 1 auto;

		max-width: none;
		margin-bottom: 0;

		border-top-right-radius: 0;
		border-bottom-right-radius: 0;

		&,
		&:focus {
			border-right: none;
		}

		&:focus + .btn {
			border: $border-width solid $color-branding-1;
		}
	}
}
