.list-group {
	@extend %list-unstyled;

	&:not(:only-child) {
		margin-bottom: $space-s;
	}
}

.list-group__item {
	position: relative;

	display: block;

	overflow: hidden;
	padding: $space-xs 0;

	text-decoration: none;

	&:not(:first-child) {
		border-top: $border-width solid $color-line;
	}

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		padding-bottom: 0;
	}

	// Override default list-item's pseudo element
	&:before {
		display: none;
	}

	.list-group--inline & {
		@include clearfix;

		display: block;

		text-align: right;

		@supports (display: flex) {
			display: flex;
		}
	}
}

.list-group__label {
	display: block;

	.list-group--inline & {
		float: left;

		max-width: 65%;

		text-align: left;

		text-overflow: ellipsis;

		@supports (display: flex) {
			flex-grow: 1;

			max-width: none;
		}
	}
}

.list-group__value {
	.list-group--inline & {
		display: inline-block;

		margin-left: $space-xs;

		@supports (display: flex) {
			flex-shrink: 0;
		}
	}
}

.list-group__badge {
	float: right;
}
