.pagination {
	@extend %list-unstyled;

	display: inline-block;

	margin: $space-s 0;
}

.pagination__item {
	display: none;

	float: left;

	margin-right: $border-width * -1; // Prevent double border between items

	&:first-child {
		border-top-left-radius: $border-radius;
		border-bottom-left-radius: $border-radius;
	}

	&:last-child {
		border-top-right-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}

	// Show all items
	@include respond-to(small) {
		display: list-item;
	}

	// Only show Pagers (Prev and Next) button on mobile
	&--pager {
		display: list-item;

		// Prev
		&:first-child {
			margin-right: $space-xxs;
		}

		// Next
		&:last-child {
			margin-left: $space-xxs;
		}
	}

	&--spacer {
		&:after {
			padding: 0 $space-xs 0 $space-xxs;

			content: '\2026';
		}
	}
}

.pagination__link {
	display: inline-block;

	padding: $space-xxs $space-xs;

	text-decoration: none;

	background: $color-white;
	border: $border-width solid $color-line;

	&:active,
	&:focus,
	&:hover {
		color: $color-white;
		text-decoration: none;

		background-color: $color-branding-1;
	}

	// Inherit border radius from parent item
	&:first-child,
	&:last-child {
		border-radius: inherit;
	}

	.is-active & {
		color: $color-white;

		font-weight: $font-weight-bold;

		background: $color-branding-1;
	}
}
