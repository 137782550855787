.breadcrumbs {
	@extend %list-unstyled;

	@include clearfix;
	@include ellipsis;

	margin-bottom: $space-s;
}

.breadcrumbs__item {
	display: inline;
}
